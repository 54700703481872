<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.companies')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'user-register-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.send') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.name_comercial"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="codeUnique"
                  :label="$t('lbl.code')"
                  disabled
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <!--<v-col
                cols="12"
                md="3"
              >
                <v-switch
                  v-model="model.is_client_corporate"
                  :disabled="$store.state.app.onlyShow"
                  :label="$t('lbl.clientCorporate')"
                  hide-details
                  class="mt-0"
                ></v-switch>
              </v-col> -->
              <v-col
                cols="12"
                md="2"
              >
                <v-switch
                  v-model="model.offline"
                  :disabled="$store.state.app.onlyShow"
                  :label="$t('lbl.offline')"
                  hide-details
                  class="mt-0"
                  @click="showModalOffline"
                ></v-switch>
              </v-col>
              <!--<v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="model.proveedor"
                  :label="$t('lbl.proveedor')"
                  :value="model.proveedor"
                  hide-details
                  class="mt-0"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="model.operador"
                  :label="$t('menu.operador')"
                  :value="model.operador"
                  hide-details
                  class="mt-0"
                ></v-checkbox>
                <v-text-field
                  v-model="model.email_contact"
                  :label="$t('lbl.contacto')"
                  outlined
                  dense
                  hide-details
                  placeholder="pepe@domain.com"
                ></v-text-field>
              </v-col>-->
            </v-row>

            <v-row>
              <v-radio-group
                v-model="perfilesRadio"
                class="mt-0 pt-0"
                row
              >
                <v-col
                  v-for="(perfil, indP) in perfilesNew"
                  :key="indP"
                  cols="12"
                  md="3"
                >
                  <v-card
                    :disabled="perfil.slug === 'agente'"
                    color="text-left"
                  >
                    <v-card-text class="d-flex flex-column justify-center align-center">
                      <v-icon
                        size="2rem"
                        :color="perfil.slug === 'agente' ? 'default' : 'primary'"
                      >
                        {{ perfil.icon }}
                      </v-icon>
                      <h3 class="mt-4 font-weight-bold text-uppercase">
                        {{ perfil.name }}
                      </h3>
                    </v-card-text>
                    <v-card-text
                      class="text-left"
                      style="min-height: 120px;"
                    >
                      <v-tooltip
                        content-class="custom-tooltip"
                        top
                        color="white"
                        style="background-color: #ffffff"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ perfil.description | truncate(180) }}
                          </span>
                        </template>
                        <span>
                          <v-card style="max-width: 250px !important;">
                            <v-card-text>
                              <p
                                class="mb-1 mt-2"
                                cols="2"
                              >
                                {{ perfil.description }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </span>
                      </v-tooltip>
                    </v-card-text>
                    <v-card-text style="text-align: center;">
                      <v-radio
                        class="justify-center align-right"
                        :value="perfil.id"
                        label=""
                        @click="setRadio(perfil)"
                      ></v-radio>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-radio-group>
            </v-row>

            <v-row v-if="perfilesRadio">
              <v-col
                cols="12"
                md="12"
              >
                <v-tabs v-model="pos">
                  <v-tab @click="setPosCotizador(0)">
                    {{ $t('lbl.infoFiscal') }}
                  </v-tab>
                  <v-tab @click="setPosCotizador(1)">
                    {{ $t('lbl.bancaria') }}
                  </v-tab>
                  <v-tab
                    v-if="
                      perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'proveedor').length === 1 &&
                        perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'agente').length === 1
                    "
                    @click="setPosCotizador(2)"
                  >
                    {{ $t('lbl.credito') }}
                  </v-tab>
                  <v-tab @click="setPosCotizador(3)">
                    {{ $t('lbl.products') }}
                  </v-tab>
                  <v-tab
                    v-if="perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'proveedor').length === 1"
                    @click="setPosCotizador(4)"
                  >
                    Markups
                  </v-tab>
                  <v-tab @click="setPosCotizador(5)">
                    {{ $t('lbl.contacts') }}
                  </v-tab>
                  <v-tab
                    v-if="perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'proveedor').length === 1"
                    @click="setPosCotizador(6)"
                  >
                    {{ $t('lbl.ejecutivos') }}
                  </v-tab>
                </v-tabs>

                <v-divider></v-divider>

                <v-tabs-items v-model="pos">
                  <v-tab-item>
                    <InfoFiscal
                      :model="model"
                      :tipos_fiscal="tipos_fiscal"
                      :countries="countries"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <Bancaria
                      :model="model"
                      :pay-ments="payMents"
                      :monedas="monedas"
                      :countries="countries"
                    />
                  </v-tab-item>
                  <v-tab-item
                    v-if="
                      perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'proveedor').length === 1 &&
                        perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'agente').length === 1
                    "
                  >
                    <Credito
                      :model="model"
                      :method-pays="methodPays"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <Products
                      :model="model"
                      :products="products"
                    />
                  </v-tab-item>
                  <v-tab-item
                    v-if="perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'proveedor').length === 1"
                  >
                    <Markups
                      v-if="model.products.length > 0 && posCotizador === 4"
                      :model="model"
                      :type-afiliados="typeAfiliados"
                      :products="products"
                      :proveedores-car="proveedoresCar"
                      :contrates-car="contratesCar"
                      :categories-car="categoriesCar"
                      :marcas-car="marcasCar"
                      :proveedores-hotel="proveedoresHotel"
                      :contrates-hotel="contratesHotel"
                      :hotels="hotelesList"
                      :rooms="roomsList"
                      :proveedores="proveedoresFlight"
                      :contrates="contratesFlight"
                      :flights="flightsList"
                      :user="user"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <Contacts
                      :model="model"
                      :contacts="model.contacts"
                      :rols="rols"
                    />
                  </v-tab-item>
                  <v-tab-item
                    v-if="perfilesNew.filter(e => e.id === perfilesRadio && e.slug !== 'proveedor').length === 1"
                  >
                    <Executives
                      :model="model"
                      :executives="model.executives"
                      :rols="rols"
                      :users="users"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>

            <!--<v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.name_contact"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.apellidos_contact"
                  :label="$t('lbl.apellidos')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.email_contact"
                  :label="$t('register.emailPerson')"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.prefijo_number_contact"
                  :label="$t('register.codePais')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.number_contact"
                  :label="$t('register.noTelefo')"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="phoneRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <h3>
                  {{ $t('register.titleBanco') }}
                </h3>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.name_fiscal_facturation"
                  :label="$t('register.nameFact')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.name_banco"
                  :label="$t('register.nameBanco')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.no_cuenta_bancaria"
                  :label="$t('register.noBanco')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="model.tipo_cuenta_bancaria"
                  :items="tipos_cuentas_bancarias"
                  :label="$t('register.tipoBanco')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="model.moneda"
                  :items="monedas"
                  :label="$t('register.moneda')"
                  outlined
                  dense
                  item-text="code"
                  item-value="code"
                  hide-details="auto"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.swift"
                  :label="$t('register.codeSWIFT')"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="swiftRules"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.iban"
                  label="IBAN o IRC"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.code_sucursal"
                  :label="$t('register.codeSucursal')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.direction_facturation"
                  :label="$t('register.address')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.localidad_facturation"
                  :label="$t('register.locality')"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="model.country_facturation"
                  :items="countries"
                  :label="$t('register.country')"
                  item-text="name"
                  item-value="name"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-if="is_proveedor && !model.is_client_corporate"
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.tipo_afiliado_id"
                  :items="typeAfiliados"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.typeAfiliado')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="!model.is_client_corporate">
              <v-col
                cols="12"
                md="12"
              >
                <strong>{{ $t('menu.products') }}</strong>
                <v-row>
                  <v-col
                    v-for="(product, i) in products"
                    :key="i"
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="nom_tipo_products_id"
                      :label="product.name"
                      :value="product.id"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <strong>{{ $t('lbl.payMethod') }}</strong>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-radio-group
                      v-model="model.method_pay_id"
                      row
                    >
                      <v-radio
                        v-for="(method, index) in methodPays"
                        :key="index"
                        :label="method.name"
                        :value="method.id"
                        @click="changeMethodPay(method)"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="typesMethodPays.length > 0"
                cols="12"
                md="12"
              >
                <strong>{{ $t('lbl.wayMethod') }}</strong>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-radio-group
                      v-model="model.type_method_pay_id"
                      row
                    >
                      <v-radio
                        v-for="(type, index) in typesMethodPays"
                        :key="index"
                        :label="type.name"
                        :value="type.id"
                        @click="changeTypeMethod(type)"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    v-if="showMonto"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="monto"
                      prefix="$"
                      :label="$t('lbl.monto')"
                      outlined
                      dense
                      hide-details
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="showTime"
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="time"
                      :items="times"
                      item-text="name"
                      item-value="id"
                      :label="$t('lbl.time')"
                      outlined
                      dense
                      hide-details
                      :suffix="$t('lbl.day')"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-row class="mt-4">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                        class="mt-2"
                      >
                        <strong>{{ $t('lbl.contacts') }}</strong>
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mt-1 mx-2"
                              fab
                              dark
                              x-small
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="addContactsAfiliados"
                            >
                              <v-icon small>
                                {{ icons.mdiPlus }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('btn.insert') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-divider class="mb-2 mt-2"></v-divider>
                    <v-row
                      v-for="(contact, indC) in contactsAfiliados"
                      :key="indC"
                    >
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <Contact
                          :pos="indC"
                          :contact="contact"
                          :product-item="nom_tipo_products_id"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-row
                          v-for="(email, indE) in contact.emails"
                          :key="indE"
                        >
                          <Email
                            :pos-contact="indC"
                            :pos="indE"
                            :email="email"
                          />
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-row
                          v-for="(phone, indT) in contact.telefonos"
                          :key="indT"
                        >
                          <Telefono
                            :pos-contact="indC"
                            :pos="indT"
                            :telefono="phone"
                          />
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>-->
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR OFFLINE -->
    <v-dialog
      v-model="isModalOffline"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('lbl.offline') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('lbl.offlineMsg') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="
              isModalOffline = !isModalOffline
              model.offline = false
            "
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="isModalOffline = !isModalOffline"
          >
            {{ $t('btn.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
} from '@mdi/js'
import { v4 as uuidv4 } from 'uuid'

import InfoFiscal from './utils/InfoFiscal.vue'
import Credito from './utils/Credito.vue'
import Bancaria from './utils/Bancaria.vue'
import Products from './utils/Products.vue'
import Markups from './utils/Markups.vue'
import Contacts from './utils/Contacts.vue'
import Executives from './utils/Executives.vue'

// import Contact from '../utils/Contact.vue'
// import Email from '../utils/Email.vue'
// import Telefono from '../utils/Telefono.vue'

export default {
  components: {
    AppCardCode,
    InfoFiscal,
    Credito,
    Bancaria,
    Products,
    Markups,
    Contacts,
    Executives,

    // Contact,
    // Email,
    // Telefono,
  },
  data() {
    return {
      isLoading: true,
      pos: 0,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
      },
      methodPays: [],
      typesMethodPays: [],
      payMents: [],
      model: {
        proveedor: false,
        operador: false,
        offline: false,
        tipo_fiscal: null,
        cif_nif_facturation: null,
        info_fiscal: {
          address: [
            {
              address: null,
            },
          ],
          code_postal: null,
          localidad: null,
          country: null,
        },

        // PRODUCTS
        products: [],

        // BANCARIA
        pay_medios: [
          {
            id: uuidv4(),
            payments_id: 0,
            payments: 'banco',
            name_fiscal_facturation: null,
            no_cuenta_bancaria: null,
            moneda: null,
            swift: null,
            iban: null,
            name_banco: null,
            code_sucursal: null,
            address: [
              {
                address: null,
              },
            ],
            code_postal: null,
            localidad: null,
            country: null,
            principal: false,
            dataZelle: {
              email: null,
              phone: null,
            },
            dataWise: null,
            dataPaypal: null,
            dataRedsys: null,
            dataSIBS: null,
          },
        ],

        // CREDITO
        method_pay_id: null,
        type_method_pay_id: null,
        data_method_pay: {
          date: this.$moment(new Date()).format('D-MM-Y hh:mm'),
          monto: null,
          time: null,
        },

        // CONTACTS
        contacts: [
          {
            id: uuidv4(),
            name: null,
            apellidos: null,
            role_id: null,
            emails: [
              {
                email: null,
              },
            ],
            phones: [
              {
                code: null,
                phone: null,
                ext: null,
                exts: [],
              },
            ],
          },
        ],

        // EXECUTIVES
        executives: [
          {
            id: uuidv4(),
            user_id: null,
            name: null,
            apellidos: null,
            role_id: null,
            email: null,
            phones: [
              {
                code: null,
                phone: null,
                ext: null,
                exts: [],
              },
            ],
          },
        ],

        // MARKUPS
        tipo_afiliado_id: null,
        markups: [],
      },
      loading: false,
      products: [],
      contacts: [],
      typeAfiliados: [],
      contactNew: {
        id: null,
        name: null,
        email: null,
        role_id: null,
        telefonos: [],
        products: [],
      },
      nom_tipo_products_contact: [],
      telefonos: [],
      telefono: {
        telefono: null,
        ext: null,
      },
      posContact: -1,
      nom_tipo_products_id: [],
      rols: [],
      users: [],
      is_proveedor: false,
      isModalOffline: false,
      idAfiliado: 0,
      showMonto: false,
      showTime: false,
      times: [],
      monto: null,
      time: null,
      codeUnique: null,

      perfiles: [],
      perfilesCheck: [],
      perfilesNew: [],
      perfilesRadio: null,
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      countries: [],
      paisUser: {},
      dataBancaria: {},
      idPerfilAgency: null,
      markups: [],

      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      urlRules: [v => this.isURL(v) || 'URL no es válida'],
      phoneRules: [v => !!v || 'Campo requerido', v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      swiftRules: [
        // v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],
      user: {},

      proveedoresHotel: [],
      proveedoresCar: [],
      proveedoresFlight: [],
      proveedores: [],
      contrates: [],
      contratesHotel: [],
      hotelesList: [],
      hotelesContrate: [],
      roomsList: [],
      roomsContrate: [],
      flightsList: [],
      contratesCar: [],
      categoriesCar: [],
      marcasCar: [],
      contratesFlight: [],
      categoryContrate: [],
      marcasContrate: [],
      flightContrate: [],
      temporadas: [],
    }
  },
  computed: {
    ...mapState({
      contactsAfiliados: state => state.app.contactsAfiliados,
      posCotizador: state => state.app.posCotizador,
      markupsList: state => state.app.markupsList,
    }),
  },
  mounted() {
    this.profile()
  },
  created() {
    if (sessionStorage.getItem('user-register-id') !== null) {
      this.idAfiliado = sessionStorage.getItem('user-register-id')
    }

    this.times.push({
      id: 7,
      name: 7,
    })
    this.times.push({
      id: 15,
      name: 15,
    })
    this.times.push({
      id: 30,
      name: 30,
    })

    this.tipos_fiscal.push('NIT')
    this.tipos_fiscal.push('NIF')
    this.tipos_fiscal.push('RTN')
    this.tipos_fiscal.push('RUC')
    this.tipos_fiscal.push('RFC')
    this.tipos_fiscal.push('RUT')
    this.tipos_fiscal.push('EIN')

    this.tipos_fiscal.sort()

    this.tipos_cuentas_bancarias.push('Ahorros')
    this.tipos_cuentas_bancarias.push('Corriente')

    this.getProducts()
    this.getTypeAfiliado()
    this.getPerfiles()
    this.getDataUser()

    this.getMethodPay()
    this.getRols()
    this.getUsers()

    this.getProveedoresHotel()
    this.getProveedoresCar()
    this.getProveedoresFlights()

    this.getContratesHotel()
    this.getContratesCar()
    this.getContratesFlight()
    this.getCategoriesCar()
    this.getMarcasCar()

    this.getHoteles()
    this.getRooms()
    this.getFlights()

    this.payMents.push({
      icon: 'mdi-bank-transfer',
      1: 1,
      name: 'Banco',
      slug: 'banco',
    })
    this.payMents.push({
      icon: 'mdi-alpha-z-box-outline',
      1: 2,
      name: 'Zelle',
      slug: 'zelle',
    })

    /* this.payMents.push({
      icon: 'mdi-contactless-payment-circle',
      1: 3,
      name: 'Wise',
      slug: 'wise',
    })
    this.payMents.push({
      icon: 'mdi-alpha-p-box-outline',
      1: 4,
      name: 'Paypal',
      slug: 'paypal',
    }) */

    // this.getItem()
  },
  methods: {
    ...mapMutations([
      'setOnlyShow',
      'setPosCotizador',
      'addContactsAfiliados',
      'deleteContactsAfiliados',
      'updateContactsAfiliados',
      'updateMarkupsList',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
      // eslint-disable-next-line no-return-assign
    },
    getDataUser() {
      /* this.axios
        .get('https://ipapi.co/json')
        .then(res => {
          this.paisUser = res.data
          this.model.data_country_contact = this.paisUser
          this.model.prefijo_number_contact = this.paisUser.country_calling_code
          this.model.prefijo_number_facturation = this.paisUser.country_calling_code
          this.model.country_facturation = this.paisUser.country_name
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getCountries()
        }) */
      this.getCountries()
    },
    getRols() {
      this.axios
        .get('role?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.rols = res.data.data
          }
        })
    },
    getUsers() {
      this.axios
        .post(
          'user/list-executive',
          { per_page: 10000 },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.users = res.data.data
          }
        })
    },
    getCountries() {
      this.axios
        .get('country_list?per_page=1000')
        .then(res => {
          this.countries = res.data.data
          this.countries.forEach(element => {
            if (this.paisUser.country_code) {
              if (element.code === this.paisUser.country_code) {
                this.model.country_facturation = element.name
              }
            }

            /* if (element.currencies) {
              element.currencies.forEach(mmm => {
                if (!this.monedas.includes(mmm.code)) {
                  this.monedas.push(mmm.code)
                }
              })
            } */
          })
        })
        .finally(() => {
          this.getCurrencies()
        })
    },
    getCurrencies() {
      this.axios
        .get('currency_list?per_page=10000&active=1')
        .then(res => {
          this.monedas = res.data.data
        })
        .finally(() => {
          this.getItem()
        })
    },
    getPerfiles() {
      this.axios
        .get('perfil_afiliado?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.perfiles = res.data.data
          this.perfiles.forEach(element => {
            if (element.slug === 'agencia') {
              this.idPerfilAgency = element.id
              this.perfilesNew.push({
                ...element,
                icon: 'mdi-wallet-travel',
              })
            } else if (element.slug === 'proveedor') {
              this.perfilesNew.push({
                ...element,
                icon: 'mdi-barcode-scan',
              })
            } else if (element.slug === 'agente') {
              this.perfilesNew.push({
                ...element,
                icon: 'mdi-badge-account-outline',
              })
            } else if (element.slug === 'corporativo') {
              this.perfilesNew.push({
                ...element,
                icon: 'mdi-badge-account-horizontal-outline',
              })
            }
          })
        })
    },
    setRadio(perfil) {
      this.model.proveedor = false
      this.model.operador = false
      if (perfil.slug === 'corporativo') {
        this.model.is_client_corporate = true
      } else {
        this.model.is_client_corporate = false
      }

      if (perfil.slug === 'agente') {
        this.model.is_agente = true
      } else {
        this.model.is_agente = false
      }

      if (perfil.slug === 'proveedor') {
        this.model.proveedor = true
      }

      if (perfil.slug === 'proveedor' || perfil.slug === 'agencia') {
        this.perfilesCheck = [perfil.id]
        if (this.perfilesCheck.includes(this.idPerfilAgency)) {
          this.is_proveedor = true
        } else {
          this.is_proveedor = false
        }
      }
    },
    getCodeUnique() {
      this.axios
        .post(
          'affiliate/code_unique',
          { id: sessionStorage.getItem('user-register-id') },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codeUnique = res.data.data.code
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000&order=asc', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products = res.data.data
        })
    },
    getTypeAfiliado() {
      this.axios
        .get(`tipo_afiliado?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            const result = []
            res.data.data.forEach(element => {
              if (element.slug !== 'propios') {
                result.push(element)
              }
            })
            this.typeAfiliados = result
          }
        })
    },
    getMethodPay() {
      this.axios
        .get('nom_methods_pay?per_page=1000&order=desc', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.methodPays = res.data.data
          if (this.model.method_pay_id) {
            if (this.methodPays.filter(e => e.id === this.model.method_pay_id).length > 0) {
              if (this.methodPays.filter(e => e.id === this.model.method_pay_id)[0].types.length > 0) {
                this.typesMethodPays = this.methodPays.filter(e => e.id === this.model.method_pay_id)[0].types
              }
            }
          } else if (this.methodPays.filter(e => e.slug === 'prepago').length > 0) {
            this.model.method_pay_id = this.methodPays.filter(e => e.slug === 'prepago')[0].id
          }
        })
    },
    getItem() {
      if (sessionStorage.getItem('user-register-id') !== null) {
        const id = sessionStorage.getItem('user-register-id')
        this.axios
          .get(`affiliate/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data

            // console.log(this.model)
            if (this.model.perfiles_id) {
              // this.perfilesCheck = this.model.perfiles_id
              // eslint-disable-next-line prefer-destructuring
              this.perfilesRadio = this.model.perfiles_id[0]
            }

            const productArr = []
            this.model.nom_tipo_products_id.forEach(element => {
              productArr.push(element)
            })
            this.model.products = productArr

            if (this.model.perfiles.length > 0) {
              this.model.perfiles.forEach(element => {
                if (element.slug === 'agencia') {
                  this.is_proveedor = true
                }
              })
            }
            if (this.model.code_unique) {
              this.codeUnique = this.model.code_unique
            } else {
              this.getCodeUnique()
            }

            if (this.model.markups) {
              if (this.model.markups.length === 0) {
                this.getMarkups()
              } else {
                this.updateMarkupsList(this.model.markups)
              }
            } else {
              this.getMarkups()
            }

            if (!this.model.products) {
              this.model.products = []
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            // this.getMethodPay()
            this.isLoading = false
          })
      } else {
        this.is_proveedor = true
        this.getCodeUnique()
        this.getMarkups()
      }
    },
    getMarkups() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('markups/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.markups = res.data.data

            this.updateMarkupsList(this.markups)
          }
        })
    },

    getProveedoresHotel() {
      this.axios
        .post('proveedors/byproduct', { slug: 'hotels', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresHotel = res.data.data
        })
    },
    getProveedoresCar() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresCar = res.data.data
        })
    },
    getProveedoresFlights() {
      this.axios
        .post('proveedors/byproduct', { slug: 'vuelos', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresFlight = res.data.data
        })
    },
    getContratesHotel() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('contrate_hotels/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesHotel = res.data.data
          }
        })
    },
    getContratesCar() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('contrate_cars/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesCar = res.data.data
          }
        })
    },
    getContratesFlight() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('contrate_flights/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesFlight = res.data.data
          }
        })
    },
    getCategoriesCar() {
      this.axios
        .get('nom_categories_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categoriesCar = res.data.data
          }
        })
    },
    getMarcasCar() {
      const json = {
        per_page: 10000,
        search: { marca_id: 0 },
      }
      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.marcasCar = res.data.data
          }
        })
    },
    getHoteles() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.hotelesList = res.data.data
          }
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=10000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roomsList = res.data.data
        })
    },
    getFlights() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('flight/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.flightsList = res.data.data
          }
        })
    },

    changeMethodPay(method) {
      if (method.types) {
        this.typesMethodPays = method.types
      }
      this.model.type_method_pay_id = null
    },
    changeTypeMethod(type) {
      if (type.slug === 'monto-de-dinero') {
        this.showMonto = true
        this.showTime = false
        this.time = null
      } else if (type.slug === 'monto-y-tiempo') {
        this.showMonto = true
        this.showTime = true
      } else if (type.slug === 'tiempo') {
        this.showTime = true
        this.showMonto = false
        this.monto = null
      }
    },

    save() {
      if (this.model.is_client_corporate) {
        this.is_proveedor = false
      }

      let procesar = true
      if (this.is_proveedor) {
        if (!this.model.name_comercial /* || !this.model.tipo_afiliado_id */) {
          procesar = false
        }
      } else if (!this.model.name_comercial) {
        procesar = false
      }
      if (procesar) {
        this.loading = true

        const json = {
          name_comercial: this.model.name_comercial,
          offline: this.model.offline,
          tipo_fiscal: this.model.tipo_fiscal,
          cif_nif_facturation: this.model.cif_nif_facturation,
          info_fiscal: this.model.info_fiscal,
          pay_medios: this.model.pay_medios,
          method_pay_id: this.model.method_pay_id,
          type_method_pay_id: this.model.type_method_pay_id,
          data_method_pay: this.model.data_method_pay,
          executives: this.model.executives,
          markups: this.markupsList,
          tipo_afiliado_id: this.model.tipo_afiliado_id,
          nom_tipo_products_id: this.model.products,
          contacts: this.model.contacts,
          codeUnique: this.codeUnique,
          proveedor: this.model.proveedor,
          operador: this.model.operador,
          is_client_corporate: this.model.is_client_corporate,
          is_agente: this.model.is_agente,
          perfil_id: this.perfilesRadio,
        }

        if (sessionStorage.getItem('user-register-id') === null) {
          this.axios
            .post('affiliate/add', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.userExist', { email: this.model.email_contact }))
                  this.$router.push({ name: 'user-register-list' })
                }
              } else {
                sessionStorage.removeItem('user-register-id')
                this.$router.push({ name: 'user-register-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .post(`affiliate/update/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.userExist', { email: this.model.email_contact }))
                  this.$router.push({ name: 'user-register-list' })
                }
              } else {
                sessionStorage.removeItem('user-register-id')
                this.$router.push({ name: 'user-register-list' })
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    addTelefono() {
      this.telefonos.push({
        ext: null,
        telefono: null,
      })
    },
    deletePhone(pos) {
      this.telefonos.splice(pos, 1)
    },

    saveContact() {
      if (this.posContact < 0) {
        this.contacts.push({
          id: Math.random()
            .toString(36)
            .substr(2, 9),
          name: this.contactNew.name,
          email: this.contactNew.email,
          role_id: this.contactNew.role_id,
          telefonos: this.telefonos,
          products: this.nom_tipo_products_contact,
        })
      }

      this.reset()
    },
    editContact(pos) {
      this.posContact = pos
      this.contactNew = this.contacts[pos]
      this.nom_tipo_products_contact = this.contacts[pos].products
      this.telefonos = this.contacts[pos].telefonos
    },
    deleteContact(pos) {
      this.contacts.splice(pos, 1)
    },

    showModalOffline() {
      if (this.model.offline) {
        this.isModalOffline = true
      }
    },

    reset() {
      this.posContact = -1
      this.contactNew = {
        id: null,
        name: null,
        email: null,
        role_id: null,
        telefonos: [],
        products: [],
      }
      this.nom_tipo_products_contact = []
      this.telefonos = []
    },
  },
}
</script>
